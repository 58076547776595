import React, { FunctionComponent } from "react";
import {
  faArrowUp,
  faChartLine,
  faTachometerAlt,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";
import {
  Spacer,
  Highlight,
  ContactForm,
  Seperator,
  Wrapper,
  Column,
  Image,
  Link,
} from "../../components";
import Container from "../../components/container";
import { HighlightData } from "../../components/highlight/HighlightData";
import { AnchorButton } from "../../components/button";
import styled from "styled-components";
import { HeaderText, Text } from "../../components/text";
import Color from "color";

const Highlights: FunctionComponent<{ color?: string }> = ({ color }) => {
  const highlights: HighlightData[] = [
    {
      title: "Security",
      icon: faUserLock,
      description:
        "Identify and remediate threats to your data, you customers, and your business.",
      link: {
        text: "Learn More",
        href: "/#",
      },
      color,
    },
    {
      title: "Scalability",
      icon: faChartLine,
      description:
        "Scale your applications seemlessly as you grow your business.",
      link: {
        text: "Learn More",
        href: "/#",
      },
      color,
    },
    {
      title: "Agility",
      icon: faTachometerAlt,
      description:
        "Allow your business to shift and adjust to take advantage of new opportunities.",
      link: {
        text: "Learn More",
        href: "/#",
      },
      color,
    },
    {
      title: "Efficiency",
      icon: faArrowUp,
      description:
        "Optimize and streamline business processes to boost your business.",
      link: {
        text: "Learn More",
        href: "#",
      },
      color,
    },
  ];
  return (
    <>
      {highlights.map((h) => (
        <Highlight key={h.title} {...h} />
      ))}
    </>
  );
};

const Hero = styled.div((props) => ({
  backgroundImage: `linear-gradient(${Color(
    (props.theme.colors as any).bgPrimary
  ).alpha(0.5)}, ${Color((props.theme.colors as any).bgPrimary)}),
  url(https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260)
  `,
  backgroundSize: "cover",
  color: "white !important",
  padding: "150px 0px",
}));

const ContactWrapper = styled.div((props) => ({
  background: `
  linear-gradient(
    ${Color((props.theme.colors as any).bgPrimary)}, 
    ${Color((props.theme.colors as any).bgPrimary).alpha(0.5)}, 
    ${Color((props.theme.colors as any).bgPrimary)}
  )
  `,
  backgroundSize: "cover",
  color: "white",
  padding: "50px 0px",
}));

export const Home: FunctionComponent = () => {
  return (
    <Wrapper>
      <Hero>
        <Container flexDirection="column" justifyContent="space-between">
          <HeaderText>
            Helping companies transform for success in the digital future
          </HeaderText>
          <Spacer size={20} vertical />
          <Text>
            Product strategy, product design/build, user experience, and process
            change to accelerate your response to digital disruption.
          </Text>
          <Spacer size={20} vertical />
          <AnchorButton my={[1, 2, 3]} variant="primary" href="/contact">
            Contact Us
          </AnchorButton>
          <Spacer size={100} vertical />
          <Container>
            <Row>
              <Highlights />
            </Row>
          </Container>
        </Container>
      </Hero>
      <Container>
        <Spacer size={100} vertical />
        <Row>
          <Column sm={12} lg={6}>
            <HeaderText>Businesses of all sizes trust Boost Beyond</HeaderText>
            <Text>
              We help clients transform their business one project at a time.
            </Text>
            <Link color="linkPrimary" href="/#">
              Learn More →
            </Link>
          </Column>
          <Column
            sm={{ order: "first", span: 12 }}
            lg={6}
            xs={{ order: "first" }}
          >
            <Image
              alt=""
              py={[1, 2, 3]}
              width="100%"
              src="https://images.pexels.com/photos/326501/pexels-photo-326501.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
        </Row>
        <Spacer size={100} vertical />
      </Container>
      <Seperator size={2} color={"#d3d3d3"} />
      <Container>
        <Spacer size={100} vertical />
        <Row>
          <Column sm={12} lg={6}>
            <Image
              py={[1, 2, 3]}
              width="100%"
              alt=""
              src="https://images.pexels.com/photos/3153198/pexels-photo-3153198.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            />
          </Column>
          <Column sm={12} lg={6}>
            <HeaderText>Strategists, technologists, designers</HeaderText>
            <Text>
              We are a team of highly talented, extremely efficient developers
              who can engineer, modify, customize, integrate, optimize and
              re-design applications or websites to a degree that can help you
              propel your application/website to the next level. As a team we
              have over 10+ years of experience and we are confident that we can
              help bring your vivid vision to life.
            </Text>
            <Link color="linkPrimary" href="/#">
              Learn More About Us →
            </Link>
          </Column>
        </Row>
        <Spacer size={100} vertical />
      </Container>
      <Seperator size={2} color={"#d3d3d3"} />
      <Container>
        <Spacer size={100} vertical />
        <Row>
          <Column sm={12} lg={6}>
            <HeaderText>Join our Team</HeaderText>
            <Text>
              We are growing fast and always looking to add talented people to
              our distributed team
            </Text>
            <Link color="linkPrimary" href="/#">
              Learn More →
            </Link>
          </Column>
          <Column
            sm={{ order: "first", span: 12 }}
            lg={6}
            xs={{ order: "first" }}
          >
            <Image
              alt=""
              width="100%"
              py={[1, 2, 3]}
              src="https://images.pexels.com/photos/3153207/pexels-photo-3153207.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
        </Row>
        <Spacer size={100} vertical />
      </Container>
      <Seperator size={2} color={"#d3d3d3"} />
      <ContactWrapper>
        <Container>
          <Spacer size={100} vertical />
          <Row>
            <Column sm={12} lg={6}>
              <HeaderText>Lets Talk</HeaderText>
              <Text>
                If forms aren’t your thing, you can always email us at
                sales@boostbeyond.us
              </Text>
            </Column>
            <Column sm={12} lg={6}>
              <ContactForm />
            </Column>
          </Row>
          <Spacer size={100} vertical />
        </Container>
      </ContactWrapper>
    </Wrapper>
  );
};
