import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const Image = styled.img<SpaceProps>(
  {
    width: "100%",
  },
  space
);

Image.defaultProps = {
  marginBottom: [4, 4, 0],
};
