import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Spacer,
  ContactForm,
  Wrapper,
  HeaderText,
  Text,
} from "../../components";
import { BreadcrumbBanner } from "../../components/banner/breadcrumb";
import ContentContainer from "../../components/container";

export const Contact = () => {
  return (
    <Wrapper>
      <BreadcrumbBanner title={"Contact Us"} description={"Lets Talk."} />
      <div>
        <ContentContainer>
          <Spacer size={100} vertical />
          <Row>
            <Col
              sm={12}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <HeaderText>Lets Talk</HeaderText>
              <Text>
                If forms aren’t your thing, you can always email us at
                sales@boostbeyond.us
              </Text>
            </Col>
            <Col sm={12} lg={6}>
              <ContactForm />
            </Col>
          </Row>
          <Spacer size={100} vertical />
        </ContentContainer>
      </div>
    </Wrapper>
  );
};
