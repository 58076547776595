import styled from "styled-components";
import { space, SpaceProps, variant } from "styled-system";

export const Button = styled.button<{ variant?: "primary" } & SpaceProps>(
  {
    border: "none",
  },
  variant({
    scale: "buttons",
  }),
  space
);

export const AnchorButton = styled.a<{ variant?: "primary" } & SpaceProps>(
  variant({
    scale: "buttons",
  }),
  space
);
