import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTheme } from "styled-components";

export const Icon: FunctionComponent<{
  icon: IconProp;
  size: SizeProp;
  color: string;
}> = ({ icon, size, color }) => {
  const theme = useTheme();
  const colorValue = theme.colors
    ? (theme.colors as Record<string, string>)[color]
    : undefined;

  return <FontAwesomeIcon icon={icon} size={size} color={colorValue} />;
};
