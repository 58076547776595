import React from "react";
import { Header, Footer } from "./components";
// @ts-ignore
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { Home, Services, About, Blog, PageNotFound } from "./pages";
import { Contact } from "./pages/contact";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import styled from "styled-components";
import GoogleFontLoader from "react-google-font-loader";

const ContentWrapper = styled.div({
  flex: 1,
});

function App() {
  return (
    <ThemeProvider theme={theme.dark}>
      <GoogleFontLoader
        fonts={[
          {
            font: "Lato",
            weights: [400, 600, 700, 800],
          },
          {
            font: "Inconsolata",
            weights: [400, 600, 800, 900],
          },
        ]}
      />
      <Header />
      <ContentWrapper>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/services">
              <Services />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/blog">
              <Blog />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Router>
      </ContentWrapper>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
