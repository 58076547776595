import { DefaultTheme } from "styled-components";

const theme: { [key: string]: DefaultTheme } = {
  light: {
    breakpoints: ["52em", "62em", "72em"],
    fontSizes: ["1.1em", "1.2em", "1.4em", "1.6em", "1.8em", "2em", "3em"],
    space: [0, 4, 8, 16, 32, 64, 128, 256],
    logo: "logo.png",
    colors: {
      bgPrimary: "#fff",
      bgAlternate: "#000",
      textPrimary: "#000",
      textSecondary: "#999",
      textAlternate: "#fff",
      linkPrimary: "#007bff",
      linkSecondary: "#ff5c5d",
      bannerText: "#fff",
    },
    buttons: {
      primary: {
        fontFamily: "Fira Code",
        backgroundColor: "#ff5c5d",
        padding: "15px 40px",
        color: "white",
        borderRadius: 500,
        "&:hover": {
          backgroundColor: "#ff5c5d",
          color: "white",
        },
      },
    },
  },
  dark: {
    breakpoints: ["40em", "52em", "64em"],
    fontSizes: [12, 14, 16, 24, 32, 48, 64, 96, 128],
    space: [0, 4, 8, 16, 32, 64, 128, 256],
    logo: "logo.white.png",
    colors: {
      bgPrimary: "#000",
      bgAlternate: "#000",
      textPrimary: "#fff",
      textSecondary: "#999",
      textAlternate: "#000",
      linkPrimary: "#007bff",
      linkSecondary: "#ff5c5d",
      bannerText: "#fff",
    },
    buttons: {
      primary: {
        backgroundColor: "#ff5c5d",
        padding: "14px 24px",
        color: "white",
        borderRadius: 500,
        "&:hover": {
          backgroundColor: "#ff5c5d",
          color: "white",
        },
      },
    },
  },
};

export default theme;
