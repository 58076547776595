import React from "react";
import { Form, Col } from "react-bootstrap";
import styled from "styled-components";
import { color, ColorProps } from "styled-system";
import { Button } from "../button";

const FormWrapper = styled(Form)<ColorProps>(color);
FormWrapper.defaultProps = {
  color: "textPrimary",
};

export const ContactForm = () => {
  return (
    <FormWrapper>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="name" placeholder="Enter Name" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter Email" />
        </Form.Group>
      </Form.Row>

      <Form.Group controlId="formGridCompanyName">
        <Form.Label>Company Name</Form.Label>
        <Form.Control placeholder="Enter Company Name" />
      </Form.Group>

      <Form.Group controlId="formGridIndustry">
        <Form.Label>Industry</Form.Label>
        <Form.Control placeholder="Enter Industry" />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} controlId="formGridBudgets">
          <Form.Label>What can we do for you?</Form.Label>
          <Form.Control as="select" defaultValue="Choose...">
            <option>{"Choose ..."}</option>
            <option>{"Cloud Migration"}</option>
            <option>{"App Design"}</option>
            <option>{"Website Development / Design"}</option>
            <option>{"SEO Optimization"}</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Anything else we should know.</Form.Label>
          <Form.Control as="textarea" placeholder="Enter Brief" />
        </Form.Group>
      </Form.Row>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </FormWrapper>
  );
};
