import React, { FunctionComponent } from "react";

export const Seperator: FunctionComponent<{
  size: number;
  color: string;
  width?: number;
}> = ({ size, color, width = 200 }) => (
  <div
    style={{
      borderTop: `${size}px solid ${color}`,
      margin: "auto",
      width,
    }}
  ></div>
);
