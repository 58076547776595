import styled from "styled-components";
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  TypographyProps,
  typography,
} from "styled-system";

export const Text = styled.p<TypographyProps & SpaceProps & ColorProps>(
  typography,
  space,
  color
);

Text.defaultProps = {
  color: "textPrimary",
  fontSize: [2, 2, 2],
};

export const HeaderText = styled(Text)({});

HeaderText.defaultProps = {
  fontFamily: "Inconsolata",
  fontWeight: 800,
  fontSize: [3, 4, 5],
};
