import React, { FunctionComponent } from "react";
import { Col } from "react-bootstrap";
import { Spacer } from "../spacer";
import { HighlightData } from "./HighlightData";
import { HeaderText, Text } from "../text";
import styled from "styled-components";
import {
  layout,
  flexbox,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  space,
  TypographyProps,
  typography,
} from "styled-system";
import { Icon } from "../icon";

export const Column = styled(Col)<
  FlexboxProps & LayoutProps & SpaceProps & TypographyProps
>(
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexbox,
  layout,
  space,
  typography
);

export const Highlight: FunctionComponent<HighlightData> = ({
  title,
  description,
  link,
  icon,
  color,
}) => {
  return (
    <Column key={title} sm={12} lg={3}>
      <Icon icon={icon} size={"2x"} color={color || ""} />
      <Spacer size={20} vertical />
      <HeaderText fontSize={[2, 2, 3]} color={color}>
        {title}
      </HeaderText>
      <Text fontSize={[0, 0, 1]} style={{ flex: 1 }} color={color}>
        {description}
      </Text>
      <a href={link.href}>{link.text} →</a>
      <Spacer size={20} vertical />
    </Column>
  );
};

Highlight.defaultProps = {
  color: "textPrimary",
};
