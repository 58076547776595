import {
  faApplePay,
  faAws,
  faCloudflare,
  faDocker,
  faGithub,
  faGoogle,
  faMicrosoft,
  faPaypal,
  faRedhat,
  faSalesforce,
} from "@fortawesome/free-brands-svg-icons";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import {
  faCloud,
  faMapMarkedAlt,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "color";
import _ from "lodash/fp";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { color, ColorProps } from "styled-system";
import {
  Column,
  ContactForm,
  HeaderText,
  Highlight,
  Image,
  Seperator,
  Spacer,
  Text,
  Wrapper,
} from "../../components";
import { BreadcrumbBanner } from "../../components/banner/breadcrumb";
import Container from "../../components/container";
import { HighlightData } from "../../components/highlight/HighlightData";

const List = styled.ul<ColorProps>(
  (props) => ({
    listStyle: "none",
  }),
  color
);

List.defaultProps = {
  color: "textPrimary",
};

const ContactWrapper = styled.div((props) => ({
  background: `
  linear-gradient(
    ${Color((props.theme.colors as any).bgPrimary)}, 
    ${Color((props.theme.colors as any).bgPrimary).alpha(0.5)}, 
    ${Color((props.theme.colors as any).bgPrimary)}
  )
  `,
  backgroundSize: "cover",
  color: "white",
  padding: "50px 0px",
}));

const partners: IconDefinition[] = [
  faAws,
  faGoogle,
  faMicrosoft,
  faApplePay,
  faCloudflare,
  faDocker,
  faRedhat,
  faSalesforce,
  faPaypal,
  faGithub,
];

const highlights: HighlightData[] = [
  {
    title: "Custom Application Development",
    link: {
      text: "Learn More",
      href: "/services",
    },
    icon: faMobile,
    description:
      "We create native and cross-platform mobile apps for iOS and Android that are compatible on all potential device and operating system features.",
  },
  {
    title: "Cloud Migrations",
    link: {
      text: "Learn More",
      href: "/services",
    },
    icon: faCloud,
    description:
      "We help companies migrate to the cloud where the can leverage the full potential of their business by removing operational overhead related to infrastructure leading to a more streamlined business.",
  },
  {
    title: "Local SEO",
    link: {
      text: "Learn More",
      href: "/services",
    },
    icon: faMapMarkedAlt,
    description:
      "Increase your businesses visiblity in local search results on Google and other Search Engines. Quickly become the top result in your area.",
  },
  {
    title: "SEO",
    link: {
      text: "Learn More",
      href: "/services",
    },
    icon: faGoogle,
    description:
      "Increase your businesses visiblity in search results on Google and other Search Engines. Drive more traffic leading to more sales.",
  },
];

export const About = () => {
  return (
    <Wrapper>
      <BreadcrumbBanner
        title={"About Us"}
        description={
          "A top tier digital agency delivering expectional results."
        }
      />
      <Container>
        <Row>{}</Row>
      </Container>
      <Spacer size={100} vertical />
      <Seperator size={3} color={"#d3d3d3"} />
      <Spacer size={100} vertical />
      <Container>
        <Row>
          <Column md={6}>
            <HeaderText>Who are we?</HeaderText>
            <Text fontSize={[1, 3]}>
              We are unique group of developers, designers, and solution
              architects with experience building technology solutions for
              companies of all sizes.
            </Text>
          </Column>
          <Column md={6}>
            <Image
              alt=""
              src="https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
        </Row>
        <Spacer size={100} vertical />
        <HeaderText>What do we specialize in?</HeaderText>
        <Container>
          <Row>
            {highlights.map((h) => (
              <Highlight {...h} />
            ))}
          </Row>
        </Container>
        <Spacer size={100} vertical />
        <Seperator size={3} color={"#d3d3d3"} />
        <Spacer size={100} vertical />
        <HeaderText>Our Partners</HeaderText>
        {_.chunk(6)(partners).map((p) => (
          <Row>
            {p.map((icon) => (
              <Column
                md={Math.floor(12 / p.length)}
                display={["block"]}
                textAlign="center"
                my={[5]}
              >
                <i title={icon.iconName.replace("-", " ").toUpperCase()}>
                  <FontAwesomeIcon icon={icon} size={"3x"} color="white" />
                </i>
              </Column>
            ))}
          </Row>
        ))}
        <Spacer size={100} vertical />
        <Seperator size={3} color={"#d3d3d3"} />
        <ContactWrapper>
          <Container>
            <Spacer size={100} vertical />
            <Row>
              <Column sm={12} lg={6}>
                <HeaderText>Lets Talk</HeaderText>
                <Text>
                  If forms aren’t your thing, you can always email us at
                  sales@boostbeyond.us
                </Text>
              </Column>
              <Column sm={12} lg={6}>
                <ContactForm />
              </Column>
            </Row>
            <Spacer size={100} vertical />
          </Container>
        </ContactWrapper>
      </Container>
    </Wrapper>
  );
};
