import React, { FunctionComponent, useState } from "react";
import cx from "classnames";
import Container from "../container";
import styled, { useTheme } from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

const Hamburger: FunctionComponent<{
  toggle: boolean;
  onClick: () => void;
  className?: string;
}> = ({ toggle, onClick, className }) => {
  return (
    <button
      className={cx(className, `hamburger`, `hamburger--minus`, {
        "is-active": toggle,
      })}
      type="button"
      onClick={onClick}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
};

const MenuButton = styled(Hamburger)<LayoutProps & { toggle: boolean }>(
  layout,
  (props) => ({
    ".hamburger-inner, .hamburger-inner::before, .hamburger-inner::after": {
      backgroundColor:
        (props.theme.colors as Record<string, string>).textPrimary +
        " !important",
    },
  })
);
export const List = styled.ul<LayoutProps & { toggle: boolean }>(
  layout,
  (props) => ({
    listStyleType: "none",
    margin: 0,
    display: props.toggle ? "block !important" : "none",
  })
);
export const ListItem = styled.li<LayoutProps & SpaceProps>(
  {
    textDecoration: "none",
  },
  layout,
  space
);

export const Link = styled.a<SpaceProps & ColorProps & TypographyProps>(
  space,
  color,
  typography
);
Link.defaultProps = {
  color: "textPrimary",
  px: [1, 2],
  py: [2, 3],
  fontSize: [1, 2, 2],
};

export const Wrapper = styled.div<ColorProps>(color);
Wrapper.defaultProps = {
  bg: "bgPrimary",
};

export const Header: FunctionComponent = () => {
  const links = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "About",
      href: "/about",
    },
    {
      text: "Services",
      href: "/services",
    },
    {
      text: "Contact",
      href: "/contact",
    },
  ].map((x) => x);

  const [toggle, setToggle] = useState(false);
  const theme = useTheme();
  return (
    <Wrapper>
      <Container
        display={["block", "block", "flex"]}
        alignItems="center"
        justifyContent="space-between"
        margin="auto"
        py={20}
        width={"100%"}
      >
        <Container justifyContent="space-between" margin={0} display="flex">
          <img height={50} alt="Boost Beyond" src={`./${theme.logo}`} />
          <MenuButton
            display={["block", "block", "none"]}
            toggle={toggle}
            onClick={() => setToggle(!toggle)}
          />
        </Container>

        <List display={["none", "none", "flex"]} toggle={toggle}>
          {links.map((l) => (
            <ListItem py={[3, 3, 0]} key={l.href}>
              <Link href={`${l.href}`}>{l.text}</Link>
            </ListItem>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};
