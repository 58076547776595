import {
  faAws,
  faGoogle,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCloud,
  faMapMarkedAlt,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { color, ColorProps } from "styled-system";
import {
  Highlight,
  Spacer,
  Image,
  Seperator,
  HeaderText,
  Text,
  Column,
  Wrapper,
} from "../../components";
import { BreadcrumbBanner } from "../../components/banner/breadcrumb";
import Container from "../../components/container";
import { HighlightData } from "../../components/highlight/HighlightData";

const highlights: HighlightData[] = [
  {
    title: "Custom Application Development",
    link: {
      text: "Learn More",
      href: "/#",
    },
    icon: faMobile,
    description:
      "We create native and cross-platform mobile apps for iOS and Android that are compatible on all potential device and operating system features.",
  },
  {
    title: "Cloud Migrations",
    link: {
      text: "Learn More",
      href: "/#",
    },
    icon: faCloud,
    description:
      "We help companies migrate to the cloud where the can leverage the full potential of their business by removing operational overhead related to infrastructure leading to a more streamlined business.",
  },
  {
    title: "Local SEO",
    link: {
      text: "Learn More",
      href: "/#",
    },
    icon: faMapMarkedAlt,
    description:
      "Increase your businesses visiblity in local search results on Google and other Search Engines. Quickly become the top result in your area.",
  },
  {
    title: "SEO",
    link: {
      text: "Learn More",
      href: "/#",
    },
    icon: faGoogle,
    description:
      "Increase your businesses visiblity in search results on Google and other Search Engines. Drive more traffic leading to more sales.",
  },
];

const List = styled.ul<ColorProps>(
  (props) => ({
    listStyle: "none",
  }),
  color
);

List.defaultProps = {
  color: "textPrimary",
};

const ListItem = styled.li`
  &:before {
    content: "•";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;
export const Services = () => {
  return (
    <Wrapper>
      <BreadcrumbBanner
        title={"Services"}
        description={
          "Redefining how technology impacts customers by helping companies embrace digital transformation."
        }
      />
      <Spacer size={100} vertical />
      <Container>
        <Row>
          {highlights.map((h) => (
            <Highlight {...h} />
          ))}
        </Row>
      </Container>
      <Spacer size={100} vertical />
      <Seperator size={3} color={"#d3d3d3"} />
      <Spacer size={100} vertical />
      <Container>
        <Row>
          <Column md={6}>
            <HeaderText>Custom Application Development</HeaderText>
            <Text>
              Unlock new business growth and opportunities with custom
              applications.
            </Text>
            <HeaderText>Our Experience</HeaderText>
            <List>
              <ListItem>Custom Mobile Applications</ListItem>
              <ListItem>Custom Websites and Web Applications</ListItem>
              <ListItem>E-Commerce Applications and Websites</ListItem>
              <ListItem>Custom Automation</ListItem>
              <ListItem>GIS Applications</ListItem>
            </List>
          </Column>
          <Column md={6} xs={{ order: "first" }}>
            <Image
              alt=""
              src="https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
        </Row>
        <Spacer size={100} vertical />
        <Seperator size={3} color={"#d3d3d3"} />
        <Spacer size={100} vertical />
        <Row>
          <Column md={6}>
            <Image
              alt=""
              src="https://images.pexels.com/photos/1148820/pexels-photo-1148820.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
          <Column md={6}>
            <HeaderText>Cloud Migrations</HeaderText>
            <Text>
              Remove operational overhead and lower your bottom line with a
              cloud migration.
            </Text>
            <HeaderText>Our Experience</HeaderText>
            <List>
              <ListItem>
                <FontAwesomeIcon icon={faAws} size={"2x"} />
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faGoogle} size={"2x"} /> (Google Cloud
                Platform)
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faMicrosoft} size={"2x"} /> (Azure)
              </ListItem>
            </List>
          </Column>
        </Row>
        <Spacer size={100} vertical />
        <Seperator size={3} color={"#d3d3d3"} />
        <Spacer size={100} vertical />
        <Row>
          <Column md={6}>
            <HeaderText>Local SEO</HeaderText>
            <Text>
              Increase your businesses visiblity in local search results on
              Google and other Search Engines. Quickly become the top result in
              your area.
            </Text>
            <HeaderText>Our Experience</HeaderText>
            <List>
              <ListItem>Google Local SEO Optimization</ListItem>
              <ListItem>Local Keyword Optimization</ListItem>
              <ListItem>Local Address Optimization</ListItem>
              <ListItem>Local Service Area Optimization</ListItem>
            </List>
          </Column>
          <Column md={6} xs={{ order: "first" }}>
            <Image
              alt=""
              src="https://images.pexels.com/photos/5921677/pexels-photo-5921677.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
        </Row>
        <Spacer size={100} vertical />
        <Seperator size={3} color={"#d3d3d3"} />
        <Spacer size={100} vertical />
        <Row>
          <Column md={6}>
            <Image
              alt=""
              src="https://images.pexels.com/photos/1148820/pexels-photo-1148820.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          </Column>
          <Column md={6}>
            <HeaderText>SEO</HeaderText>
            <Text>
              Increase your businesses visiblity in search results on Google and
              other Search Engines. Drive more traffic leading to more sales
              with the help of Boost Beyond.
            </Text>
            <HeaderText>Our Experience</HeaderText>
            <List>
              <ListItem>Keyword Optimization</ListItem>
              <ListItem>Voice Search Optimization</ListItem>
              <ListItem>Page Load Optimization</ListItem>
            </List>
          </Column>
        </Row>
        <Spacer size={100} vertical />
      </Container>
    </Wrapper>
  );
};
