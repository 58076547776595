import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  margin,
  MarginProps,
  SpaceProps,
} from "styled-system";

const Container = styled.div<
  ColorProps & FlexboxProps & LayoutProps & SpaceProps & MarginProps
>(space, layout, flexbox, margin);

Container.defaultProps = {
  maxWidth: 1200,
  margin: "auto",
  px: [3, 3, 0],
};

export default Container;
