import React, { FunctionComponent } from "react";

export const Spacer: FunctionComponent<{
  size: number;
  vertical?: boolean;
  horizontal?: boolean;
}> = ({ size, vertical, horizontal }) => (
  <div
    style={{
      height: vertical ? size : "100%",
      width: horizontal ? size : "100%",
    }}
  ></div>
);
