import React, { FunctionComponent } from "react";
import Container from "../container";
import { Row } from "react-bootstrap";
import { Link, ListItem, Wrapper } from "../header";
import styled, { useTheme } from "styled-components";
import { flexbox, FlexboxProps, layout, LayoutProps } from "styled-system";
import { Column } from "../highlight";
import { Text } from "../text";
const List = styled.ul<LayoutProps & FlexboxProps>(
  layout,
  {
    listStyleType: "none",
    margin: 0,
  },
  flexbox
);

export const Footer: FunctionComponent = () => {
  const theme = useTheme();
  const links = ["Home", "About", "Services", "Contact", "Blog"].map((x) => x);
  return (
    <Wrapper>
      <hr style={{ borderTop: "1px solid #d3d3d3" }} />
      <Container maxWidth={1200} margin="auto">
        <Row>
          <Column display={"block"} xs={12} md={6}>
            <img
              alt="Boost Beyond"
              height="50px"
              src={`./${theme.logo}`}
              className={""}
            />
          </Column>
          <Column xs={12} md={12} lg={6}>
            <List
              display={["block", "block", "flex"]}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {links.map((l) => (
                <ListItem py={[3, 3, 0]} key={l}>
                  <Link href={`/${l.toLowerCase()}`}>{l}</Link>
                </ListItem>
              ))}
            </List>
          </Column>
        </Row>
      </Container>
      <hr style={{ borderTop: "1px solid #d3d3d3" }} />
      <Container alignItems="center">
        <Text fontSize={[1, 1, 1]} textAlign="center">
          Copyright &copy; {new Date().getFullYear()} Boost Beyond. All Rights
          Reserved
        </Text>
      </Container>
    </Wrapper>
  );
};
