import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../container";
import { Spacer } from "../spacer";
import Color from "color";
import { HeaderText, Text } from "../text";
import { Link } from "../header";
import { color, ColorProps } from "styled-system";

const BreadcrumbWrapper = styled.div((props) => ({
  background: `
  linear-gradient(
    ${Color((props.theme.colors as any).bgPrimary).alpha(0.5)}, 
    ${Color((props.theme.colors as any).bgPrimary)}
  ),
  url(https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260)
  `,
  backgroundSize: "cover",
  padding: "150px 0px",
}));

const Span = styled.span<ColorProps>(color);
Span.defaultProps = {
  color: "textPrimary",
};

export const BreadcrumbBanner: FunctionComponent<{
  title: string;
  description: string;
}> = ({ title, description }) => {
  return (
    <BreadcrumbWrapper>
      <Container>
        <HeaderText fontSize={[3, 4, 4]}>{title}</HeaderText>
        <Text fontSize={[2, 3, 4]}>{description}</Text>
        <Span>
          <Link href="/">Boost Beyond</Link>
          {` > ${title}`}
        </Span>
        <Spacer size={100} vertical />
      </Container>
    </BreadcrumbWrapper>
  );
};
