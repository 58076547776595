import React from "react";
import { BreadcrumbBanner } from "../../components/banner/breadcrumb";

export const Blog = () => {
  return (
    <>
      <BreadcrumbBanner title={"Blog"} description={"Coming Soon"} />
    </>
  );
};
