import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, Text } from "../../components";
import Container from "../../components/container";

export const PageNotFound = () => {
  return (
    <Container
      display="flex"
      alignItems="center"
      flexDirection="column"
      height="100%"
      style={{ background: "black" }}
    >
      <Container
        display="flex"
        alignItems="center"
        flexDirection="column"
        height="100%"
        py={200}
      >
        <FontAwesomeIcon icon={faExclamation} size={"2x"} color={"white"} />
        <Text fontSize={[2, 2, 3]} textAlign="center">
          404 | Page not found
        </Text>
        <Link href="/">Go back</Link>
      </Container>
    </Container>
  );
};
